import { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { useNotificationStorage } from '@src/hooks/useNotificationStorage';

export const LandingAuthProtectedGuard = () => {
	const { client } = useRoutingContext();
	const location = useLocation();
	const { getNotificationStorage, removeNotificationStorage } = useNotificationStorage();
	const [notification] = useState(() => getNotificationStorage());

	if (!!notification) {
		removeNotificationStorage();

		return (
			<Navigate
				to={client.notification()}
				replace
				state={{
					component: notification.component,
					from: location,
				}}
			/>
		);
	}

	return <Outlet />;
};
