import { Outlet, Navigate } from 'react-router';

import { useSignupVerification } from '@src/api/hooks/auth/useSignupVerification';
import { ExpiredVerificationLink } from '@pages/Auth/ExpiredVerificationLink/ExpiredVerificationLink';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import { BaseErrorDisplay } from '@pages/Notification/components/BaseErrorDisplay';
import { useNavigateWithQueryParamsPersistence } from '@src/hooks/useNavigateWithQueryParamsPersistence';

export const SignupProtectedGuard = () => {
	const { withQueryParams } = useNavigateWithQueryParamsPersistence();
	const { isLoading, isError, isErrorExpiredToken, isErrorNotFound } = useSignupVerification();

	if (isLoading) {
		return <FullScreenLoader />;
	}

	if (isErrorExpiredToken) {
		return <ExpiredVerificationLink />;
	}

	if (isErrorNotFound) {
		return <Navigate to={withQueryParams('/')} replace />;
	}

	if (isError) {
		return <BaseErrorDisplay fieldName="notFound" />;
	}

	return <Outlet />;
};
