import { ConfigService } from '@src/config/ConfigService';
import { getTenant } from '@src/utilities/getTenant';
import { cleanUrl } from '@src/utilities/helpers';

const dataLinkTypeOnPageNavigation = {
	'data-linktype': 'onPageNavigation',
};

const buildHomeUrlWithIcid = (icid: string) => cleanUrl(`${ConfigService.env.base.homeEndpoint}?icid=${icid}`);

const buildHomeButtonTracing = (linkId: string) => ({
	...dataLinkTypeOnPageNavigation,
	'data-linkid': linkId,
});

const defaultTenantConfig = {
	reiseplaner: {
		home: 'mydertour.de',
		homeUrl: ConfigService.env.base.appEndpoint,
		homeButtonTracing: {},
	},
	dertour: {
		home: 'dertour.de',
		homeUrl: buildHomeUrlWithIcid('ooh_cs-account_gen-login_cs_2upd-intern_link-dt_login_helper_nn-nn-nn_nn'),
		homeButtonTracing: buildHomeButtonTracing('helper_link_dertourde'),
	},
	its: {
		home: 'its.de',
		homeUrl: buildHomeUrlWithIcid('ooh_cs-account_gen-login_cs_2upd-intern_link-its_login_helper_nn-nn-nn_nn'),
		homeButtonTracing: buildHomeButtonTracing('helper_link_its'),
	},
	mwr: {
		home: 'meiers-weltreisen.de',
		homeUrl: buildHomeUrlWithIcid('ooh_cs-account_gen-login_cs_2upd-intern_link-mwr_login_helper_nn-nn-nn_nn'),
		homeButtonTracing: buildHomeButtonTracing('helper_link_mwr'),
	},
};

export const tenantConfig: Record<string, any> = {
	development: defaultTenantConfig,
	staging: defaultTenantConfig,
	production: defaultTenantConfig,
};

export const currentTenantConfig = tenantConfig[ConfigService.env.base.nodeEnv];

export const getTenantCurrentConfig = (tenant?: string) => {
	return currentTenantConfig[tenant ?? getTenant()];
};
