import { Navigate, Outlet, useLocation } from 'react-router';

import { IEmailProtectedRoute, IEmailProtectedRouteState } from '@src/router/guards/EmailProtectedGuard/types';
import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { useNavigateWithQueryParamsPersistence } from '@src/hooks/useNavigateWithQueryParamsPersistence';
import { useLocationSearchParams } from '@src/hooks/useLocationSearchParams';

export const EmailProtectedGuard = ({ route = 'home' }: IEmailProtectedRoute) => {
	const { client } = useRoutingContext();
	const location = useLocation();
	const { withQueryParams } = useNavigateWithQueryParamsPersistence();
	const state = location.state as IEmailProtectedRouteState;
	const searchParams = useLocationSearchParams();
	const email = state?.email || searchParams?.email;

	if (!email) {
		return <Navigate to={withQueryParams(client[route]())} replace />;
	}

	return <Outlet />;
};
