import { Route } from 'react-router';

import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import { ScrollRestoration } from '@components/ScrollRestoration/ScrollRestoration';
import { BaseLayout } from '@components/BaseLayout/BaseLayout';
import { LandingAuth } from '@pages/Auth/LandingAuth/LandingAuth';
import { Login } from '@pages/Auth/Login/Login';
import { Signup } from '@pages/Auth/Signup/Signup';
import { NotificationPage } from '@pages/Notification/Notification';
import { Faqs } from '@pages/Legal/Faqs/Faqs';
import { Imprint } from '@pages/Legal/Imprint/Imprint';
import { PrivacyPolicy } from '@pages/Legal/PrivacyPolicy/PrivacyPolicy';
import { TermsOfUse } from '@pages/Legal/TermsOfUse/TermsOfUse';
import { LandingAuthProtectedGuard } from '@src/router/guards/LandingAuthProtectedGuard/LandingAuthProtectedGuard';
import { EmailProtectedGuard } from '@src/router/guards/EmailProtectedGuard/EmailProtectedGuard';
import { SignupProtectedGuard } from '@src/router/guards/SignupProtectedGuard/SignupProtectedGuard';
import { ForgotPassword } from '@pages/Auth/ForgotPassword/ForgotPassword';
import { ResetPassword } from '@pages/Auth/ResetPassword/ResetPassword';
import SomethingWentWrong from '@pages/Notification/components/SomethingWentWrong';
import { Platforms } from '@pages/Platforms/Platforms';
import { AuthMediaLayout } from '@components/AuthMediaLayout/AuthMediaLayout';

const useGetRoutes = () => {
	const { client } = useRoutingContext();

	return (
		<Route element={<ScrollRestoration />} errorElement={<SomethingWentWrong />}>
			<Route element={<BaseLayout />}>
				<Route path={client.platforms.path} element={<Platforms />} />

				<Route element={<AuthMediaLayout />}>
					<Route element={<LandingAuthProtectedGuard />}>
						<Route path={client.home.path} element={<LandingAuth />} />
					</Route>

					<Route element={<EmailProtectedGuard />}>
						<Route path={client.login.path} element={<Login />} />
					</Route>
				</Route>

				<Route element={<SignupProtectedGuard />}>
					<Route path={client.signup.path} element={<Signup />} />
				</Route>

				<Route path={client.forgotPassword.path} element={<ForgotPassword />} />

				<Route element={<EmailProtectedGuard route="resetPassword" />}>
					<Route path={client.resetPassword.path} element={<ResetPassword />} />
				</Route>
			</Route>

			<Route element={<BaseLayout withContainer />}>
				<Route path={client.imprint.path} element={<Imprint />} />

				<Route path={client.privacyPolicy.path} element={<PrivacyPolicy />} />

				<Route path={client.termsOfUse.path} element={<TermsOfUse />} />

				<Route path={client.faqs.path} element={<Faqs />} />
			</Route>

			<Route path={client.notification.path} element={<NotificationPage />} />
		</Route>
	);
};

export default useGetRoutes;
