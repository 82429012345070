import { useTranslation } from 'react-i18next';
import { ErrorResponse, isRouteErrorResponse, useRouteError } from 'react-router';

import { useRoutingContext } from '@src/contexts/RoutingContext/RoutingContext';
import BaseButton from '@ui/BaseButton/BaseButton';
import { useUsercentricsEffectOnce } from '@src/hooks/useUsercentricsEffectOnce';
import { useAdobeAnalytics } from '@src/contexts/AdobeAnalytics/AdobeAnalyticsContext';

import { ReactComponent as IconErrorState } from '@assets/svg/error-state.svg';
import {
	StyledNotification,
	StyledNotificationContent,
	StyledNotificationBody,
	StyledNotificationEntry,
	StyledNotificationHead,
	StyledNotificationHeadIcon,
	StyledNotificationTitle,
	StyledNotificationActions,
	StyledNotificationStack,
} from '@pages/Notification/styles';
import { ConfigService } from '@src/config/ConfigService';

interface InternalErrorResponse extends ErrorResponse {
	error: Error;
}

const SomethingWentWrong = () => {
	const errorResponse = useRouteError() as InternalErrorResponse;
	const { client, externalRoute } = useRoutingContext();
	const { t } = useTranslation();
	const { dataLayer } = useAdobeAnalytics();
	const isDev = ConfigService.isDevelopment;

	useUsercentricsEffectOnce(() => {
		const status = isRouteErrorResponse(errorResponse) ? `${errorResponse.status}` : '500';
		const statusText = isRouteErrorResponse(errorResponse)
			? `${errorResponse.statusText}`
			: t('common.unexpectedError');

		dataLayer.pageView('errorPage', {
			statusCode: Number(status),
			error: {
				context: status,
				reason: status,
				name: statusText,
				type: status,
			},
		});
	});

	const handleReload = () => {
		window.location.replace(externalRoute(client.home()));
	};

	return (
		<StyledNotification>
			<StyledNotificationContent full={isDev}>
				<StyledNotificationHead>
					<StyledNotificationHeadIcon>
						<IconErrorState width="100%" height={300} />
					</StyledNotificationHeadIcon>

					<StyledNotificationTitle>Oops!</StyledNotificationTitle>
				</StyledNotificationHead>

				<StyledNotificationBody>
					<StyledNotificationEntry>{t('common.somethingWentWrong')}</StyledNotificationEntry>

					{isDev && isRouteErrorResponse(errorResponse) && (
						<StyledNotificationStack>
							<pre>
								<code>{errorResponse?.error?.stack}</code>
							</pre>
						</StyledNotificationStack>
					)}
				</StyledNotificationBody>

				<StyledNotificationActions>
					<BaseButton onClick={handleReload}>{t('common.reloadPage')}</BaseButton>
				</StyledNotificationActions>
			</StyledNotificationContent>
		</StyledNotification>
	);
};

export default SomethingWentWrong;
