import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useTheme } from 'styled-components';

import { StyledButtonBack } from '@ui/BaseBackButton/styles';

interface BaseButtonBackProps {
	text?: string;
}

export const BaseButtonBack = ({ text }: BaseButtonBackProps) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const { t } = useTranslation();

	const { arrowLeft: IconArrowLeft } = theme.icon;

	return (
		<StyledButtonBack onClick={() => navigate(-1)} data-testid="btn-go-back">
			<IconArrowLeft width={17} height={17} />
			{text ?? t('common.back')}
		</StyledButtonBack>
	);
};

export default BaseButtonBack;
