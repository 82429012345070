import { Location } from 'react-router';

export const Notifications = {
	UnexpectedError: 'UnexpectedError',
	NotFound: 'NotFound',
	SomethingWentWrong: 'SomethingWentWrong',
	RegisterCheckYourEmail: 'RegisterCheckYourEmail',
	DeactivatedAccount: 'DeactivatedAccount',
	ReactivatedAccount: 'ReactivatedAccount',
	PasswordSuccessfullyChanged: 'PasswordSuccessfullyChanged',
} as const;

export type NotificationsTypes = typeof Notifications;
export type NotificationType = keyof NotificationsTypes;

export interface ILocationNotificationState {
	component: NotificationType;
	from: Location;
}

export interface IUseNotificationStateValue {
	state: ILocationNotificationState;
	location: Location;
}
