import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router';

import useGetRoutes from '@src/router/routes';

const AppRoutes = () => {
	const routes = useGetRoutes();
	const router = createBrowserRouter(createRoutesFromElements(routes));

	return <RouterProvider router={router} />;
};

export default AppRoutes;
